.JvCEoW_aisling {
  width: 100vw;
  min-height: 100vh;
  letter-spacing: 1px;
  text-align: left;
  color: #4a3137;
  background-color: #f9b8cc;
  border-top: 6px solid #4a3137;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-family: Courier, monospace;
  font-size: 13px;
  line-height: 1.75;
  display: flex;
}

.JvCEoW_aisling h1 {
  margin-bottom: 12px;
  font-size: 40px;
  font-style: italic;
  font-weight: 500;
}

.JvCEoW_aisling .JvCEoW_mistyBtn {
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 5px 20px;
  position: relative;
  top: 3px;
}

.JvCEoW_aisling .JvCEoW_mistyBtn svg {
  width: 24px;
  height: 24px;
  fill: #4a3137;
}

.JvCEoW_aisling .JvCEoW_credits {
  border-top: 1px solid #4a3137;
  margin-top: 40px;
  padding-top: 40px;
  font-size: 10px;
}

@keyframes JvCEoW_heartUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0)rotate(10deg);
  }

  50% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  90% {
    opacity: 0;
    transform: translate3d(0, -35vh, 0)scale(1.2)rotate(-10deg);
  }

  100% {
    opacity: 0;
  }
}

:root {
  --purple: #240f3a;
  --light-purple: #f9f5fd;
  --chronicle: "Chronicle Display A", "Chronicle Display B";
  --knockout: "Knockout 34 A", "Knockout 34 B";
  --knockoutCondensed: "Knockout 28 A", "Knockout 28 B";
}

body {
  font-family: Cardo, serif;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
}

html {
  box-sizing: border-box;
  font-family: var(--chronicle);
  letter-spacing: .05em;
  font-size: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--light-purple);
}

a {
  color: var(--purple);
}

a:hover, a:focus {
  text-decoration: underline;
}

.suazYW_app {
  height: 100vh;
  width: 100vw;
  grid-template-columns: 250px 250px 1fr;
  display: grid;
}

@media (max-width: 1300px) {
  .suazYW_app {
    grid-template: 1fr 1fr / 225px 1fr;
  }
}

@media (max-width: 1080px) {
  .suazYW_app {
    grid-template: 225px 1fr / 225px 1fr;
  }
}

@media (max-width: 500px) {
  .suazYW_app {
    grid-template: 170px 1fr 250px / 1fr;
  }
}

.suazYW_header {
  background-color: var(--purple);
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 1300px) {
  .suazYW_header {
    grid-row: 1;
  }
}

.suazYW_logo {
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: .67;
}

@media (max-width: 1080px) {
  .suazYW_logo {
    font-size: 3rem;
  }
}

.suazYW_nav {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 1080px) {
  .suazYW_nav {
    align-items: flex-start;
    padding: 0 2rem;
  }
}

@media (max-width: 500px) {
  .suazYW_nav {
    align-items: center;
  }
}

.suazYW_nav .suazYW_navLink {
  color: var(--purple);
  letter-spacing: 3px;
  margin-bottom: 1rem;
  font-family: Oswald, sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  transition: transform .3s ease-out;
  display: block;
}

@media (max-width: 500px) {
  .suazYW_nav .suazYW_navLink {
    text-align: center;
  }
}

.suazYW_nav .suazYW_navLink:hover, .suazYW_nav .suazYW_navLink:active, .suazYW_nav .suazYW_navLink:focus {
  transform: translateX(5px);
}

@media (max-width: 500px) {
  :is(.suazYW_nav .suazYW_navLink:hover, .suazYW_nav .suazYW_navLink:active, .suazYW_nav .suazYW_navLink:focus) {
    transform: none;
  }
}

.suazYW_nav .suazYW_navLink:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 1080px) {
  .suazYW_nav {
    grid-column: 2;
  }
}

@media (max-width: 500px) {
  .suazYW_nav {
    grid-area: 3 / 1;
  }
}

.suazYW_about {
  background-color: var(--light-purple);
  color: var(--purple);
  align-items: center;
  padding: 6rem 4rem;
  display: flex;
}

@media (max-width: 1300px) {
  .suazYW_about {
    grid-row: 1 / span 2;
  }
}

@media (max-width: 1080px) {
  .suazYW_about {
    grid-area: 2 / 1 / auto / span 2;
  }
}

@media (max-width: 500px) {
  .suazYW_about {
    grid-column: 1;
    padding: 3rem 2rem;
  }
}

.suazYW_aboutHeader {
  letter-spacing: 3px;
  margin-bottom: 1.6rem;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

@media (max-width: 500px) {
  .suazYW_aboutHeader {
    margin-bottom: 1.1rem;
    font-size: 1.5rem;
  }
}

.suazYW_aboutText {
  max-width: 57rem;
  font-size: 1.5rem;
  line-height: 1.75;
}

@media (max-width: 1300px) {
  .suazYW_aboutText {
    font-size: 1.5rem;
  }
}

@media (max-width: 768px) {
  .suazYW_aboutText {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.suazYW_aboutLink {
  letter-spacing: 3px;
  margin-top: 1.6rem;
  font-family: Oswald, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  transition: transform .3s ease-out;
  display: inline-block;
}

.suazYW_aboutLink + .suazYW_aboutLink {
  margin-left: 20px;
}

.suazYW_aboutLink:hover, .suazYW_aboutLink:active, .suazYW_aboutLink:focus {
  text-decoration: none;
}

/*# sourceMappingURL=index.0417c347.css.map */
